class XWindow extends HTMLElement {
  constructor() {
    super();

    this._title = '';

    this.attachShadow({mode: 'open'});

    this.shadowRoot.innerHTML = `
      <style>
        .window {
          border: .2rem solid rgba(0, 0, 0, .6);
          border-radius: 1rem;
        }
        
        .window__title-bar {
          padding: .5em 0;
          background-color: #9999ffcc;
          border-bottom: .2rem solid rgba(0, 0, 0, .6);
          border-radius: .8rem .8rem 0 0;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          align-items: center;
        }
        
        .window__title-bar__middle {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          
          font-weight: bold;
          color: white;
          text-align: center;
        }
        
        .window__content {
          background-color: rgba(255, 255, 255, .8);
          border-radius: 0 0 .8rem .8rem;
          padding: 1rem;
        }
        
        .title-bar-dots {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: .5rem;
        }
        
        .dot {
          width: 1.96rem;
          height: 1.1em;
          border-radius: 50%;
          margin-right: 1rem;
        }
        
        .red {
          background: #FF5F56;
        }
        
        .yellow {
          background: #FFBD2E;
        }
        
        .green {
          background: #27C93F;
        }
      </style>
      <div class="window">
        <div class="window__title-bar">
          <div class="window__title-bar__left title-bar-dots">
            <div class="dot red"></div>
            <div class="dot yellow"></div>
            <div class="dot green"></div>
          </div>
          <div class="window__title-bar__middle">Home</div>
          <div class="window__title-bar__right">&nbsp;</div>
        </div>
        <div class="window__content">
          <slot><slot>
        </div>
      </div>
    `;
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue === newValue) return;

    if (name === 'window-title') {
      this._title = newValue;
      this.shadowRoot.querySelector('.window__title-bar__middle').innerText = this._title;
    }
  }

  static get observedAttributes() {
    return ['window-title'];
  }
}

customElements.define('x-window', XWindow);